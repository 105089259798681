import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './accountSuccess.module.css';

import ActionCard from '../components/ActionCard';
import Container from '../components/Container';
import Layout from '../components/Layout/Layout';
import { Button, Spacer } from '../uiBook';
import { useAppContext } from '../context/useAppContext';

const OrderConfirmPage = (props) => {
  const { orderStatus } = useAppContext();

  const OrderConfirmedContent = (props) => (
    <div className={styles.root}>
      {orderStatus === 'pending' && (
        <>
          <h1>Thank You!</h1>
          <Spacer height={24} />
          <p>
            We are now processing your order, and we will send you a Paypal
            invoice shortly. In the meantime, if you have any questions feel
            free to email us at info@marionancientart.com
          </p>
        </>
      )}
      {orderStatus === 'processing' && (
        <>
          <p>
            <h1>Thank You!</h1>
            <Spacer height={24} />
            We are now processing your order, and we will send you a
            confirmation email shortly. In the meantime, if you have any
            questions feel free to email us at info@marionancientart.com
          </p>
        </>
      )}
      {orderStatus === 'cancelled' && (
        <>
          <p>
            <h2>Payment Cancelled</h2>
            <Spacer height={24} />
            Continue shopping or contact us if you have any questions.
            <Spacer height={24} />
            <Button onClick={() => navigate('/shop')}>Continue Shopping</Button>
          </p>
        </>
      )}
      {orderStatus === 'error' && (
        <>
          <p>
            <h1>Payment Error</h1>
            Please try again or contact us if you have any questions.
          </p>
        </>
      )}

      <div className={styles.actionContainer}>
        <ActionCard
          title={'Shop'}
          icon={'bag'}
          subtitle={'Continue Shopping'}
          link={'/shop'}
        />

        {/* <ActionCard
          title={'FAQs'}
          icon={'question'}
          subtitle={'Check out FAQs page'}
          link={'/faq'}
        /> */}

        <ActionCard
          title={'Contact Us'}
          icon={'phone'}
          subtitle={'Reach out to us'}
          link={'/support#contact'}
        />
      </div>
    </div>
  );

  return (
    <Layout disablePaddingBottom>
      <Container size={'medium'}>
        <OrderConfirmedContent />
      </Container>
    </Layout>
  );
};

export default OrderConfirmPage;
